const Carousel: React.FC = () => {
  return (
    <>
      <div className="flex-col w-full py-5 hidden md:block">
        <div className="mx-auto carousel p-4 space-x-4 bg-gray-200 rounded-box">
          <div id="item1" className="carousel-item mx-auto">
            <img
              src="/assets/images/carousel/halloween.png"
              className="rounded-box"
              alt="example"
            />
          </div>
          <div id="item2" className="carousel-item">
            <img
              src="/assets/images/carousel/searching.png"
              className="rounded-box "
              alt="example"
            />
          </div>
          <div id="item3" className="carousel-item">
            <img
              src="/assets/images/carousel/autumn.png"
              className="rounded-box"
              alt="example"
            />
          </div>
          <div id="item4" className="carousel-item">
            <img
              src="/assets/images/carousel/monster.png"
              className="rounded-box"
              alt="example"
            />
          </div>
        </div>
      </div>
      <div className="flex-col w-full py-5 md:hidden">
        <img
          src="/assets/images/carousel/halloween.png"
          className="rounded-box pb-5 w-1/2 mx-auto"
          alt="example"
        />
        <img
          src="/assets/images/carousel/searching.png"
          className="rounded-box pb-5 w-1/2 mx-auto"
          alt="example"
        />
        <img
          src="/assets/images/carousel/autumn.png"
          className="rounded-box pb-5 w-1/2 mx-auto"
          alt="example"
        />
      </div>
    </>
  );
};

export default Carousel;
