import Footer from "./Footer";
import Menu from "./Menu";

const Portfolio: React.FC = () => {
  const pictures = [
    {
      url: "/assets/images/thumbs/halloween.png",
      title: "test",
    },
    {
      url: "/assets/images/thumbs/autumn.png",
      title: "test",
    },
    {
      url: "/assets/images/thumbs/monster.png",
      title: "test",
    },
    {
      url: "/assets/images/thumbs/searching.png",
      title: "test",
    },
  ];
  return (
    <>
      <div className="mx-auto flex-col  w-full md:w-3/5 justify-center">
        <header className="App-header mb-5">
          <img src="logo.png" alt="logo" width="200" />
          <img src="/assets/images/logo-name-sm.png" alt="logo" height="40" />
        </header>
        <Menu></Menu>

        <div className="my-20 grid md:grid-cols-4 grid-cols-2 gap-2 mx-auto">
          {pictures.map((picture) => {
            return (
              <div className="w-36 h-36 md:w-64 md:h-64 bg-gray-200 mx-auto">
                <img
                  src={picture.url}
                  alt=""
                  className="w-36 h-36 md:w-64 md:h-64"
                />
              </div>
            );
          })}
        </div>

        <Footer></Footer>
      </div>
    </>
  );
};

export default Portfolio;
