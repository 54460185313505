import Footer from "./Footer";
import Menu from "./Menu";

const About: React.FC = () => {
  return (
    <>
      <div className="mx-auto flex-col  w-full md:w-3/5 justify-center">
        <header className="App-header mb-5">
          <img src="logo.png" alt="logo" width="200" />
          <img src="/assets/images/logo-name-sm.png" alt="logo" height="40" />
        </header>
        <Menu></Menu>

        <div className="grid md:grid-cols-2  md:w-3/4  w-full mx-auto my-20">
          <img
            className="mx-auto w-48 md:w-64 md:mt-10"
            src="/assets/images/henk.png"
            alt="henk"
          />

          <div className="mx-5 md:mx-0">
            <div className="text-xl text-bold ">About</div>
            <p className="mt-10">
              Henk is a character designer and cartoonist based in Deventer, the
              Netherlands.
            </p>

            <p className="mt-10">
              Henk attended the University of Twenty where he received a
              Master's degree in Computer Science. He's a full time software
              developer, but in his spare time he loves to draw.
            </p>

            <p className="mt-10">
              For more information on Henk's work, to get in touch regarding
              employment opportunities, or to just say hello, feel free to email
              at: hello@designhenk.nl
            </p>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </>
  );
};

export default About;
