const Footer: React.FC = () => {
  return (
    <div className="w-full flex  justify-between border-t-2 border-gray-200 py-5">
      <div className="ml-5 md:ml-0">Copyright © All rights reserved.</div>
      {/* <div>social</div> */}
    </div>
  );
};

export default Footer;
