import Carousel from "./Carousel";
import Footer from "./Footer";
import Menu from "./Menu";

const Home: React.FC = () => {
  return (
    <div className="mx-auto flex-col  w-full md:w-3/5 justify-center">
      <header className="App-header mb-5">
        <img src="logo.png" alt="logo" width="200" />
        <img src="/assets/images/logo-name-sm.png" alt="logo" height="40" />
      </header>
      <Menu></Menu>

      <div className="flex-col w-full justify-between">
        <img
          className="mx-auto my-20"
          src="/assets/images/cartoon.jpg"
          alt="main"
        ></img>
      </div>
      <Carousel></Carousel>

      <Footer></Footer>
    </div>
  );
};

export default Home;
