const Menu: React.FC = () => {
  return (
    <div className="w-full flex justify-center py-2">
      <div>
        <a href="/">HOME</a>
      </div>
      <div className="ml-20">
        <a href="/portfolio">PORTFOLIO</a>
      </div>
      <div className="ml-20">
        <a href="/about">ABOUT</a>
      </div>
    </div>
  );
};

export default Menu;
